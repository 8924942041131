import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Neem Karoli Baba ",
  "subhead": "My Guru",
  "date": "2019-12-16T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Words cannot merely express my feeling when writing about my Maharaji, Ever since I heard about him there is a never ending
desire to meet him IRL. I am unnsure whether this is a Guru- disciple relation or something beyond that, only he knows.
I got to know about Maharaji through Krishna Das and Ram Dass; Somewhere in the deep heart, I know him very well.
Getting addicted to bhajans of KD makes my journey towards Maharaji Simpler. `}</p>
    <p>{`As of today, I have visted Kainchi Dam thrice and was able to spend some quality time in the Ashram. Unlike every other Ashrams I have ever been to, Kainchi Dam stands out in it's own way. Mostly due to the celestial energy of Babaji. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      